import { Divider, Grid, Stack, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Layout from '../components/layout';
import SocialIcons from '../components/socialIcons';
import TextSection from '../components/textsection';
import { ulPadder, discordImage } from './bots.module.css';

const BotsPage = () => {
  return (
    <Layout pageTitle="Bots | EtherMagellan" pageDescription="All the available discord bots Ether Magellan has for purchase" size="md">
      <Stack spacing={3}>
        <TextSection sectionTitle="OpenSea Discord Bot Features">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center">
              <ul className={ulPadder}>
                <li>
                  Selling Activity events posted into channels at timed intervals
                  <ul>
                    <li>Post sales over a certain value into chat channels to increase hype</li>
                  </ul>
                </li>
                <li>Opensea NFT Image Lookups</li>
                <li>Floor Lookups</li>
                <ul>
                  <li>Floor can be reported in the bot's status</li>
                </ul>
                <li>Help command details all available functionality of the bot</li>
                <li>Any messaging, branding, and images used by a version of my Bot is customizable to your discord</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <Stack alignItems="center">
                <Typography variant="h6">Example Bot Status</Typography>
                <StaticImage layout="constrained" height={300} className={discordImage} src="../images/opensea_bot_sidebar.png" />
              </Stack>
            </Grid>
          </Grid>
        </TextSection>
        <TextSection sectionTitle="OpenSea Bot Output Examples">
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Stack alignItems="center" maxWidth={500}>
                <Typography variant="h6">.image command</Typography>
                <Typography>
                  Anyone in the discord can reference or just show off their favorite NFTs in your collection with this command.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage width={500} className={discordImage} src="../images/opensea_bot_image.png" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item sm={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Stack alignItems="center" maxWidth={500}>
                <Typography variant="h6">.check command</Typography>
                <Typography>
                  For people who want to get an idea of the more in depth details of any NFT in your collection, this command allows your
                  community to view any NFTs traits as well as its rarity compared to the rest of the collection.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage width={500} className={discordImage} src="../images/opensea_bot_check.png" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item sm={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Stack alignItems="center" maxWidth={500}>
                <Typography variant="h6">.floor command</Typography>
                <Typography>
                  This command conveniently allows your community to check in on what the floor price is across the entirety of your
                  collection. It also provides links to the NFTs that are at the floor price and what percentage of the all time high sold
                  price is the current overall floor.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage width={500} className={discordImage} src="../images/opensea_bot_floor.png" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item sm={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Stack alignItems="center" maxWidth={500}>
                <Typography variant="h6">.trait command</Typography>
                <Typography>
                  This command allows your community to see what the floor price is specifically for a particular trait. This is a great
                  tool for collectors if they are shopping around for a particular trait! Just like the .floor commands, this outputs
                  percentage of ATH sold price and links to available NFTs with the desired traits.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage width={500} className={discordImage} src="../images/opensea_bot_trait.png" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item sm={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <Stack alignItems="center" maxWidth={500}>
                <Typography variant="h6">.help command</Typography>
                <Typography>
                  As always, if there is any confusion as to what functionality is currently available on this bot, this command will
                  outline available features as well as example calls to make bot use as simple as possible.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <StaticImage width={500} className={discordImage} src="../images/opensea_bot_help.png" />
            </Grid>
          </Grid>
        </TextSection>
        <TextSection sectionTitle="Pricing">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Stack divider={<Divider />} width={360}>
                <Typography variant="h5" textAlign="center">
                  Free Trial
                </Typography>
                <ul style={{ margin: 0 }}>
                  <li>Launching a new community? Let me get you started free of charge</li>
                  <li>1+ items from your collection(s) let’s me become a part of your community</li>
                </ul>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center">
              <Stack divider={<Divider />} width={360}>
                <Typography variant="h5" textAlign="center">
                  Paid Plan
                </Typography>
                <ul style={{ margin: 0 }}>
                  <li>Typically after 100 ETH in sales</li>
                  <li>One-time 1.5 ETH Payment</li>
                </ul>
              </Stack>
            </Grid>
          </Grid>
        </TextSection>
        <TextSection sectionTitle="Why Me?">
          <div>
            As a big believer in the NFT and Web3 space, I think there is tremendous value in cultivating vibrant communities and supporting
            one another. As a dev I'll try my best to customize and tailor my bots to the community's needs whenever possible and be an
            active member in the communities I build for. Let's take this opportunity to grow and build together!
          </div>
        </TextSection>
        <TextSection sectionTitle="Contact Me">
          <div>I'm always active on twitter and discord so just shoot me a message at either of those to get the ball rolling!</div>
          <SocialIcons />
        </TextSection>
      </Stack>
    </Layout>
  );
};

export default BotsPage;
